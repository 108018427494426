export const GET_EXTRACURRICULAR_REQUEST = 'GET_EXTRACURRICULAR_REQUEST'
export const GET_EXTRACURRICULAR_SUCCESS = 'GET_EXTRACURRICULAR_SUCCESS'
export const GET_EXTRACURRICULAR_FAILURE = 'GET_EXTRACURRICULAR_FAILURE'

export const ADD_EXTRACURRICULAR_REQUEST = 'ADD_EXTRACURRICULAR_REQUEST'
export const ADD_EXTRACURRICULAR_SUCCESS = 'ADD_EXTRACURRICULAR_SUCCESS'
export const ADD_EXTRACURRICULAR_FAILURE = 'ADD_EXTRACURRICULAR_FAILURE'

export const UPDATE_EXTRACURRICULAR_REQUEST = 'UPDATE_EXTRACURRICULAR_REQUEST'
export const UPDATE_EXTRACURRICULAR_SUCCESS = 'UPDATE_EXTRACURRICULAR_SUCCESS'
export const UPDATE_EXTRACURRICULAR_FAILURE = 'UPDATE_EXTRACURRICULAR_FAILURE'
