import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {useSelector} from 'react-redux'

import './alert.css'

const AlertDialogBox = () => {
	const alerts = useSelector((state) => state.alert)

	const [showAlert, setShowAlert] = useState(false)
	const handleCloseWarning = () => {
		setShowAlert(false)
	}

	useEffect(() => {
		if (alerts.length > 0) {
			setShowAlert(true)
		}
	}, [alerts])

	return (
		<div>
			{alerts.map((alert) => (
				<Modal
					key={alert.id}
					show={showAlert}
					onHide={handleCloseWarning}
					className='warning-modal'
				>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<h6
							className='mb-0 pb-5 black-color warning-msg-title text-center'
							style={{textTransform: 'capitalize'}}
						>
							{alert.msg}
						</h6>
					</Modal.Body>
				</Modal>
			))}
		</div>
	)
}

export default AlertDialogBox
