import axios from "axios";

import {
  STUDENT_LOGIN_REQUEST,
  STUDENT_LOGIN_SUCCESS,
  STUDENT_LOGIN_FAILURE,
  ADVISOR_LOGIN_REQUEST,
  ADVISOR_LOGIN_SUCCESS,
  ADVISOR_LOGIN_FAILURE,
  RELOAD_USER_DETAILS_REQUEST,
  RELOAD_USER_DETAILS_SUCCESS,
  RELOAD_USER_DETAILS_FAILURE,
  LOAD_USER_DETAILS_REQUEST,
  LOAD_USER_DETAILS_SUCCESS,
  LOAD_USER_DETAILS_FAILURE,
  LOGOUT,
} from "../constants/authConstants";

// import {reactLocalStorage} from 'reactjs-localstorage'
// import {token, email} from '../config/localStorage'

import { setAlert } from "./alertActions";
import { loadUserDetailsAPI, loginAPI, studentLoginAPI } from "../config/API";

//LOGIN USER
export const advisorLogin = (email, password) => async (dispatch) => {
  dispatch({
    type: ADVISOR_LOGIN_REQUEST,
  });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let body = {
      EMAIL: email,
      PASSWORD: password,
    };

    const res = await axios.post(loginAPI, body, config);

    if (res.data.success === 0) {
      dispatch(
        setAlert(
          "Unable to login, please check your email and password",
          "danger"
        )
      );
      dispatch({
        type: ADVISOR_LOGIN_FAILURE,
      });
    } else {
      dispatch({
        type: ADVISOR_LOGIN_SUCCESS,
        payload: {
          token: res.data.token,
          isAuthenticated: true,
          email: email,
          fullName: res.data.fullName,
          userID: res.data.user_id,
          clientID: res.data.client_id,
          refreshToken: res.data.refresh_token,
          userRole: res.data.userRole,
        },
      });
      dispatch(reloadAuthDetails(res.data.token));
    }

    // window.location.href = '/'
  } catch (err) {
    console.error(err);
    dispatch(
      setAlert(
        "Unable to login, please check your email and password",
        "danger"
      )
    );
    dispatch({
      type: ADVISOR_LOGIN_FAILURE,
    });
  }
};

//STUDENT LOGIN
export const studentLogin = (email, password) => async (dispatch) => {
  dispatch({
    type: STUDENT_LOGIN_REQUEST,
  });

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let body = {
      EMAIL: email,
      PASSWORD: password,
    };
    const res = await axios.post(studentLoginAPI, body, config);

    if (res.data.success === 0) {
      dispatch({
        type: STUDENT_LOGIN_FAILURE,
      });
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${res.data.token}`,
          "Content-Type": "application/json",
        },
      };

      const reloadResult = await axios.get(loadUserDetailsAPI, config);
      if (reloadResult.data.success === 0) {
        dispatch({
          type: STUDENT_LOGIN_FAILURE,
        });
        dispatch(
          setAlert(
            "Unable to login, if issue persists please contact support",
            "danger"
          )
        );
      } else {
        if (
          reloadResult.data.userRole !== 1 &&
          reloadResult.data.isParent !== 1
        ) {
          dispatch({
            type: STUDENT_LOGIN_FAILURE,
          });
          dispatch(setAlert("Advisors please use the advisor login", "danger"));
        } else {
          dispatch({
            type: STUDENT_LOGIN_SUCCESS,
            payload: {
              token: res.data.token,
              isAuthenticated: true,
              email: reloadResult.data.email,
              isParent: reloadResult.data.isParent,
              actScore: reloadResult.data.actScore,
              citizenship: reloadResult.data.citizenship,
              fullName: reloadResult.data.fullName,
              gender: reloadResult.data.gender,
              gpaData: reloadResult.data.gpaData,
              satScore: reloadResult.data.satScore,
              userRole: reloadResult.data.userRole,
              userID: reloadResult.data.user_id,
              discussionBoardID: reloadResult.data.discussionBoardID,
            },
          });
          dispatch(reloadAuthDetails(res.data.token));
        }
      }
    }

    // window.location.href = '/'
  } catch (err) {
    console.error(err);
    dispatch(
      setAlert(
        "Unable to login, please check your email and password",
        "danger"
      )
    );
    dispatch({
      type: STUDENT_LOGIN_FAILURE,
    });
  }
};

//Reload User Details
export const reloadAuthDetails = (token) => async (dispatch) => {
  dispatch({
    type: RELOAD_USER_DETAILS_REQUEST,
  });
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(loadUserDetailsAPI, config);
    if (res.data.success === 0) {
      dispatch({
        type: RELOAD_USER_DETAILS_FAILURE,
      });
    } else {
      dispatch({
        type: RELOAD_USER_DETAILS_SUCCESS,
        payload: {
          token: token,
          isAuthenticated: true,
          email: res.data.email,
          isParent: res.data.isParent,
          actScore: res.data.actScore,
          citizenship: res.data.citizenship,
          fullName: res.data.fullName,
          gender: res.data.gender,
          gpaData: res.data.gpaData,
          satScore: res.data.satScore,
          userRole: res.data.userRole,
          userID: res.data.user_id,
          discussionBoardID: res.data.discussionBoardID,
        },
      });
    }
    //window.location.href = '/'
  } catch (err) {
    console.error(err);
    dispatch({
      type: RELOAD_USER_DETAILS_FAILURE,
    });
  }
};

//Load User
export const loadAuthDetails = (tokenIncoming) => async (dispatch) => {
  // console.log(tokenIncoming)
  dispatch({
    type: LOAD_USER_DETAILS_REQUEST,
  });

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenIncoming}`,
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(loadUserDetailsAPI, config);
    if (res.data.success === 0) {
      dispatch({
        type: LOAD_USER_DETAILS_FAILURE,
      });
    } else {
      dispatch({
        type: LOAD_USER_DETAILS_SUCCESS,
        payload: {
          token: tokenIncoming,
          isAuthenticated: true,
          email: res.data.email,
          isParent: res.data.isParent,
          actScore: res.data.actScore,
          citizenship: res.data.citizenship,
          fullName: res.data.fullName,
          gender: res.data.gender,
          gpaData: res.data.gpaData,
          satScore: res.data.satScore,
          userRole: res.data.userRole,
          userID: res.data.user_id,
          discussionBoardID: res.data.discussionBoardID,
        },
      });
    }
    window.location.href = "/";
    //need to manually push users to homepage, if they are visiting the landing page
  } catch (err) {
    console.error(err);
    dispatch({
      type: LOAD_USER_DETAILS_FAILURE,
    });
  }
};

//LOGOUT
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });

  //window.location.href = '/'
};
