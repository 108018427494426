//userRole - 1 === Student
//userRole - 2 === Advisor
//userRole - 3 === Lead Advisor
//userRole - 4 === Editor
//userRole - 5 === Manager

//

import React, { Fragment, Suspense, lazy, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Landing from "../pages/LandingInfo/LandingInfo";
import Loading from "../components/Loading/Loading";
import { useSelector } from "react-redux";
import axios from "axios";
// import Faq from '../pages/FAQ'
import { APIGetUserDetailsShortlisting } from "../config/API";

//Login imports
const AdvisorLogin = lazy(() => import("../pages/Login/AdvisorLogin/"));
const StudentLogin = lazy(() => import("../pages/Login/StudentLogin/"));

const Home = lazy(() => import("../pages/Home"));
const MeetingN = lazy(() => import("../pages/Meeting"));
const Tasks = lazy(() => import("../pages/Tasks"));
const DiscussionBoard = lazy(() => import("../pages/DiscussionBoard"));

const Profile = lazy(() => import("../pages/Profile"));
const HighSchoolGrades = lazy(() => import("../pages/Grades"));
const SecondGrades = lazy(() => import("../pages/Grades/SecondGrades.jsx"));
const Testing = lazy(() => import("../pages/Testing"));

//US Colleges
const ShortlistingInstruction = lazy(() =>
  import("../pages/Shortlisting/USShortlisting/ShortlistingInstruction.jsx")
);
const CollegeShortlistingUS = lazy(() =>
  import("../pages/Shortlisting/USShortlisting/")
);
const ShortlistedColleges = lazy(() =>
  import("../pages/Shortlisting/USShortlisting/ShortlistedColleges.jsx")
);

//Summer Programs
const SummerPrograms = lazy(() =>
  import("../pages/SummerPrograms/SummerProgram")
);
const SummerPlan = lazy(() => import("../pages/SummerPrograms/SummerPlan"));
const SummerStrategy = lazy(() =>
  import("../pages/SummerPrograms/SummerStrategy")
);
const IndependentProjects = lazy(() => import("../pages/IndependentProjects"));
const IndependentProjects2 = lazy(() =>
  import("../pages/IndependentProjects/index2")
);
const IndependentProjects3 = lazy(() =>
  import("../pages/IndependentProjects/index3")
);

//ESSAY
const ReadingList = lazy(() => import("../pages/Essay/ReadingList.jsx"));
const SummerSchool = lazy(() => import("../pages/Essay/SummerSchool.jsx"));
const Colleges = lazy(() => import("../pages/Essay/Colleges.jsx"));

const SchoolSupport = lazy(() => import("../pages/SchoolSupport"));
const Connections = lazy(() => import("../pages/Connections"));
const AdvisorNotes = lazy(() => import("../pages/AdvisorNotes"));

const Calendar = lazy(() => import("../pages/Calendar"));

const CollegeShortlistingUK = lazy(() =>
  import("../pages/Shortlisting/CollegeShortlistingUK.jsx")
);
const ShortlistedCollegeUK = lazy(() =>
  import("../pages/Shortlisting/ShortlistedCollegeUK.jsx")
);
const CollegeShortlistingCanada = lazy(() =>
  import("../pages/Shortlisting/CollegeShortlistingCanada.jsx")
);
const CollegeShortlistingIntegratedMedicine = lazy(() =>
  import("../pages/Shortlisting/IntegratedMedicine")
);

const Extracurriculars = lazy(() => import("../pages/Extracurriculars"));

const NGOList = lazy(() => import("../pages/Resources/NGOList.jsx"));
const Hackathons = lazy(() => import("../pages/Resources/Hackathons.jsx"));
const Internships = lazy(() => import("../pages/Resources/Internships.jsx"));
const Olympiads = lazy(() => import("../pages/Resources/Olympiads.jsx"));
const Competitions = lazy(() => import("../pages/Resources/Competitions.jsx"));
const OnlineCourses = lazy(() =>
  import("../pages/Resources/OnlineCourses.jsx")
);
const FinancialAid = lazy(() => import("../pages/Resources/FinancialAid.jsx"));
const Platforms = lazy(() => import("../pages/Resources/Platforms.jsx"));
const Professors = lazy(() => import("../pages/Resources/Professors.jsx"));
const Research = lazy(() => import("../pages/Resources/Research.jsx"));
const ResearchFocus = lazy(() =>
  import("../pages/Resources/ResearchFocus.jsx")
);

const ResearchFocus2 = lazy(() => import("../pages/Resources/ResearchFocus2"));
const ResearchFocus3 = lazy(() => import("../pages/Resources/ResearchFocus3"));

const Downloads = lazy(() => import("../pages/Resources/Downloads.jsx"));
const RecommendedBooks = lazy(() =>
  import("../pages/Resources/RecommendedBooks.jsx")
);

const StudentShortlistedCollegeUS = lazy(() =>
  import("../pages/Shortlisting/StudentShortlistedCollegeUS.jsx")
);
const StudentShortlistedCollegeUK = lazy(() =>
  import("../pages/Shortlisting/StudentShortlistedCollegeUK.jsx")
);
const FinalShortlistedCollege = lazy(() =>
  import("../pages/Shortlisting/FinalShortlistedCollege.jsx")
);

const StudentList = lazy(() => import("../pages/StudentList"));
const Recording = lazy(() => import("../pages/Meeting/Recording.jsx"));
const NotFound = lazy(() => import("../pages/Error"));
const ZoomComponent = lazy(() => import("../pages/Zoom/zoom_meetings"));
const Manager = lazy(() => import("../pages/Manager"));
const Manager2 = lazy(() => import("../pages/Manager/Manager2"));
const ManagerResources = lazy(() => import("../pages/Manager/Resources"));
//const DiscussionBoardN = lazy(() => import("../pages/discussion-board/DiscussionBoard"));
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const ShortlistedCollegeCanada = lazy(() =>
  import("../pages/Shortlisting/ShortlistedCollegeCanada.jsx")
);
const ShortlistedCollegeApac = lazy(() =>
  import("../pages/Apac/ShortlistedCollegeApac.jsx")
);
const ShortlistedCollegeEurope = lazy(() =>
  import("../pages/europe/ShortlistedCollegeEurope.jsx")
);
const ShortlistedCollegeIntegratedMed = lazy(() =>
  import(
    "../pages/Shortlisting/IntegratedMedicine/ShortlistedCollegeIntegratedMed.js"
  )
);
const Apac = lazy(() => import("../pages/Apac"));
const Europe = lazy(() => import("../pages/europe"));
//const Loading = lazy(() => import("../components/Loading/Loading"));
const ManagerCompetitions = lazy(() =>
  import("../pages/Manager/Resources/Competitions")
);

const ManagerDownloads = lazy(() =>
  import("../pages/Manager/Resources/Downloads")
);

const ManagerHackathons = lazy(() =>
  import("../pages/Manager/Resources/Hackathons")
);

const ManagerInternships = lazy(() =>
  import("../pages/Manager/Resources/Internships")
);

const ManagerNGOList = lazy(() => import("../pages/Manager/Resources/NGOList"));
const ManagerOlympiads = lazy(() =>
  import("../pages/Manager/Resources/Olympiads")
);
const ManagerOnlineCourses = lazy(() =>
  import("../pages/Manager/Resources/OnlineCourses")
);
const ManagerPlatforms = lazy(() =>
  import("../pages/Manager/Resources/Platforms")
);
const ManagerProfessors = lazy(() =>
  import("../pages/Manager/Resources/Professors")
);
const ManagerRecommendedBooks = lazy(() =>
  import("../pages/Manager/Resources/RecommendedBooks")
);
const ManagerResearch = lazy(() =>
  import("../pages/Manager/Resources/Research")
);
const Fafsa = lazy(() => import("../pages/FAFSA"));

const CollegeShortlistAssessment = lazy(() =>
  import("../pages/Manager/AdvisorAsssesment/CollegeShortlist")
);

const AcademicAssessment = lazy(() =>
  import("../pages/Manager/AdvisorAsssesment/Academic")
);

const ExtracurricularAssessment = lazy(() =>
  import("../pages/Manager/AdvisorAsssesment/Extracurricular")
);

const ResearchAssessment = lazy(() =>
  import("../pages/Manager/AdvisorAsssesment/Research")
);

const SummerPlanAssessment = lazy(() =>
  import("../pages/Manager/AdvisorAsssesment/SummerPlan")
);

const Faq = lazy(() => import("../pages/FAQ"));
const scholarship = lazy(() =>
  import("../pages/FinancialAid/MeritScholarships")
);

const AdvisorCases = lazy(() => import("../pages/AdvisorCases"));

const EssayDashboard = lazy(() => import("../pages/EssayDashboard"));

const ManagerEssayDashboard = lazy(() =>
  import("../pages/Manager/EssayDashboard")
);

const EssayTheme = lazy(() => import("../pages/Essay/Theme"));

const AdmissionsPitch = lazy(() => import("../pages/AdmissionsPitch"));

const Routes = () => {
  const auth = useSelector((state) => state.auth);
  const { userRole, isParent, loading } = auth;
  const [requiredFieldStatus, setRequiredFieldStatus] = useState(null);

  // to push incase student has not filled his details
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.CollegePassToken_AP}`,
      },
    };
    const fetchMandatoryDetails = async (email) => {
      email !== null &&
        (await axios
          .get(`${APIGetUserDetailsShortlisting}${email}`, config)
          .then((e) => {
            if (
              e?.data?.body?.major &&
              e?.data?.body?.gpa &&
              e?.data?.body?.gender &&
              // (e.data.body.satScore || e.data.body.actScore) &&
              // (e.data.body.satScore !== 0 || e.data.body.actScore !== 0) &&
              e?.data?.body?.citizenship
            ) {
              setRequiredFieldStatus(true);
            } else {
              setRequiredFieldStatus(false);
            }
            // console.log('REQQ FIELD STATUS INSIDE UE', requiredFieldStatus)
          }));
    };
    fetchMandatoryDetails(auth.email);
  }, [auth.email]);

  let LoginComponent = AdvisorLogin;

  if (loading) {
    LoginComponent = Loading;
  } else {
    if (userRole !== null && requiredFieldStatus !== null) {
      if (userRole >= 2 && userRole < 5) {
        // LoginComponent = StudentList;
        LoginComponent = AdvisorCases;
      } else if (userRole === 5 || userRole === 6) {
        // LoginComponent = Manager;
        LoginComponent = Manager2;
      } else if (userRole === 1 && isParent === 1) {
        LoginComponent = StudentList;
      } else if (userRole === 1 && requiredFieldStatus === false) {
        LoginComponent = Profile;
      } else {
        LoginComponent = Home;
      }
    } else {
      LoginComponent = AdvisorLogin;
    }
  }

  return (
    <Fragment>
      <Suspense fallback={<Loading />}>
        <Switch onUpdate={() => window.scrollTo(0, 0)}>
          {/* LANDING PAGE */}

          <Route exact path="/landing/:landingInfo" component={Landing} />

          {/* HOME PAGE */}
          <Route exact path="/" component={LoginComponent} />

          {/* LOGIN SCREENS */}
          <Route exact path="/login" component={StudentLogin} />
          <Route exact path="/advisor-login" component={AdvisorLogin} />
          <Route exact path="/student-login" component={StudentLogin} />
          <Route exact path="/parent-login" component={StudentLogin} />

          {/* Manager Routes */}
          {(auth.userRole === 5 ||
            auth.userID === 67 ||
            auth.userID === 15048) && (
            <PrivateRoute exact path="/students-report" component={Manager} />
          )}
          {(auth.userRole === 5 ||
            auth.userID === 67 ||
            auth.userID === 15048) && (
            <PrivateRoute
              exact
              path="/manager-essay-report"
              component={ManagerEssayDashboard}
            />
          )}

          <PrivateRoute
            exact
            path="/manager-resources"
            component={ManagerResources}
          />
          <PrivateRoute
            exact
            path="/manager-resources-competitions"
            component={ManagerCompetitions}
          />

          <PrivateRoute
            exact
            path="/manager-resources-downloads"
            component={ManagerDownloads}
          />

          <PrivateRoute
            exact
            path="/manager-resources-hackathons"
            component={ManagerHackathons}
          />

          <PrivateRoute
            exact
            path="/manager-resources-internships"
            component={ManagerInternships}
          />

          <PrivateRoute
            exact
            path="/manager-resources-ngos"
            component={ManagerNGOList}
          />
          <PrivateRoute
            exact
            path="/manager-resources-olympiads"
            component={ManagerOlympiads}
          />

          <PrivateRoute
            exact
            path="/manager-resources-online-courses"
            component={ManagerOnlineCourses}
          />

          <PrivateRoute
            exact
            path="/manager-resources-platforms"
            component={ManagerPlatforms}
          />

          <PrivateRoute
            exact
            path="/manager-resources-professors"
            component={ManagerProfessors}
          />

          <PrivateRoute
            exact
            path="/manager-resources-books"
            component={ManagerRecommendedBooks}
          />

          <PrivateRoute
            exact
            path="/manager-resources-research"
            component={ManagerResearch}
          />

          {/* STUDENT ROUTES */}
          {/* STUDENT PROFILE ROUTES */}
          <PrivateRoute exact path="/home" component={LoginComponent} />
          <PrivateRoute exact path="/meeting" component={MeetingN} />
          <PrivateRoute exact path="/tasks" component={Tasks} />
          {/* <PrivateRoute exact path="/chat" component={DiscussionBoard} /> */}
          <PrivateRoute exact path="/email" component={DiscussionBoard} />

          {/* STUDENT PROFILE ROUTES */}
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/grades" component={HighSchoolGrades} />
          <PrivateRoute
            exact
            path="/gpa/value/:grade"
            component={SecondGrades}
          />
          <PrivateRoute exact path="/testing" component={Testing} />
          <PrivateRoute
            exact
            path="/extracurriculars"
            component={Extracurriculars}
          />
          <PrivateRoute exact path="/faq" component={Faq} />
          <PrivateRoute exact path="/scholarships" component={scholarship} />
          <PrivateRoute exact path="/us" component={CollegeShortlistingUS} />
          <PrivateRoute
            exact
            path="/instructions"
            component={ShortlistingInstruction}
          />
          <PrivateRoute
            exact
            path="/shortlisted-colleges"
            component={ShortlistedColleges}
          />
          <PrivateRoute
            exact
            path="/student-shortlisted-us"
            component={StudentShortlistedCollegeUS}
          />

          <PrivateRoute exact path="/uk" component={CollegeShortlistingUK} />
          <PrivateRoute
            exact
            path="/student-shortlisted-uk"
            component={StudentShortlistedCollegeUK}
          />
          <PrivateRoute
            exact
            path="/shortlisted-colleges-uk"
            component={ShortlistedCollegeUK}
          />

          <PrivateRoute
            exact
            path="/shortlisted-colleges-canada"
            component={ShortlistedCollegeCanada}
          />
          <PrivateRoute
            exact
            path="/:student_id/shortlisted-colleges-canada"
            component={ShortlistedCollegeCanada}
          />
          <PrivateRoute
            exact
            path="/canada"
            component={CollegeShortlistingCanada}
          />

          <PrivateRoute
            exact
            path="/shortlisted-colleges-integrated-medicine"
            component={ShortlistedCollegeIntegratedMed}
          />

          <PrivateRoute
            exact
            path="/integrated-medicine"
            component={CollegeShortlistingIntegratedMedicine}
          />

          <PrivateRoute exact path="/apac" component={Apac} />
          <PrivateRoute
            exact
            path="/shortlisted-college-apac"
            component={ShortlistedCollegeApac}
          />

          <PrivateRoute exact path="/europe" component={Europe} />
          <PrivateRoute
            exact
            path="/shortlisted-college-europe"
            component={ShortlistedCollegeEurope}
          />

          <PrivateRoute
            exact
            path="/summer-programs"
            component={SummerPrograms}
          />
          <PrivateRoute exact path="/summer-plan" component={SummerPlan} />
          <PrivateRoute
            exact
            path="/summer-strategy"
            component={SummerStrategy}
          />
          <PrivateRoute
            exact
            path="/independent-projects"
            component={IndependentProjects}
          />
          <PrivateRoute
            exact
            path="/independent-project-1"
            component={IndependentProjects}
          />
          <PrivateRoute
            exact
            path="/independent-project-2"
            component={IndependentProjects2}
          />

          <PrivateRoute
            exact
            path="/independent-project-3"
            component={IndependentProjects3}
          />
          <PrivateRoute exact path="/reading-list" component={ReadingList} />
          <PrivateRoute exact path="/summer-school" component={SummerSchool} />
          <PrivateRoute exact path="/colleges" component={Colleges} />
          {/* Essay Theme */}
          <PrivateRoute exact path="/essay-theme" component={EssayTheme} />

          {/* Admissions Pitch */}
          <PrivateRoute
            exact
            path="/admissions-pitch"
            component={AdmissionsPitch}
          />

          <PrivateRoute
            exact
            path="/school-support"
            component={SchoolSupport}
          />
          <PrivateRoute exact path="/connections" component={Connections} />
          <PrivateRoute exact path="/advisor-notes" component={AdvisorNotes} />
          <PrivateRoute exact path="/ngo-list" component={NGOList} />
          <PrivateRoute exact path="/hackathons" component={Hackathons} />
          <PrivateRoute exact path="/internships" component={Internships} />
          <PrivateRoute exact path="/olympiads" component={Olympiads} />
          <PrivateRoute exact path="/competitions" component={Competitions} />
          <PrivateRoute exact path="/financial-aid" component={FinancialAid} />
          <PrivateRoute exact path="/fafsa" component={Fafsa} />
          <PrivateRoute
            exact
            path="/online-courses"
            component={OnlineCourses}
          />

          <PrivateRoute exact path="/platforms" component={Platforms} />
          <PrivateRoute exact path="/professors" component={Professors} />
          <PrivateRoute exact path="/research" component={Research} />
          <PrivateRoute
            exact
            path="/research-focus"
            component={ResearchFocus}
          />

          <PrivateRoute
            exact
            path="/research-focus-1"
            component={ResearchFocus}
          />
          <PrivateRoute
            exact
            path="/research-focus-2"
            component={ResearchFocus2}
          />

          <PrivateRoute
            exact
            path="/research-focus-3"
            component={ResearchFocus3}
          />
          <PrivateRoute
            exact
            path="/recommended-books"
            component={RecommendedBooks}
          />
          <PrivateRoute
            exact
            path="/resource-downloads"
            component={Downloads}
          />

          {/* ADVISOR ROUTES */}
          <PrivateRoute exact path="/calendar" component={Calendar} />

          <PrivateRoute exact path="/student-list" component={StudentList} />
          <PrivateRoute exact path="/essay-report" component={EssayDashboard} />
          <PrivateRoute exact path="/my-cases" component={AdvisorCases} />

          <PrivateRoute exact path="/:student_id" component={Home} />
          <PrivateRoute exact path="/:student_id/profile" component={Profile} />
          <PrivateRoute exact path="/:student_id/tasks" component={Tasks} />

          <PrivateRoute
            exact
            path="/:student_id/grades"
            component={HighSchoolGrades}
          />
          <PrivateRoute
            exact
            path="/:student_id/gpa/value/:grade"
            component={SecondGrades}
          />
          <PrivateRoute exact path="/:student_id/testing" component={Testing} />
          <PrivateRoute
            exact
            path="/:student_id/connections"
            component={Connections}
          />
          {/* <PrivateRoute exact path='/:student_id/advisor-notes' component={AdvisorNotes} /> */}
          <PrivateRoute
            exact
            path="/:student_id/school-support"
            component={SchoolSupport}
          />
          <PrivateRoute
            exact
            path="/:student_id/calendar"
            component={Calendar}
          />
          <PrivateRoute
            exact
            path="/:student_id/us"
            component={CollegeShortlistingUS}
          />
          <PrivateRoute
            exact
            path="/:student_id/instructions"
            component={ShortlistingInstruction}
          />
          <PrivateRoute
            exact
            path="/:student_id/uk"
            component={CollegeShortlistingUK}
          />
          <PrivateRoute exact path="/:student_id/apac" component={Apac} />
          <PrivateRoute exact path="/:student_id/europe" component={Europe} />
          <PrivateRoute
            exact
            path="/:student_id/shortlisted-college-apac"
            component={ShortlistedCollegeApac}
          />
          <PrivateRoute
            exact
            path="/:student_id/shortlisted-college-europe"
            component={ShortlistedCollegeEurope}
          />
          <PrivateRoute
            exact
            path="/:student_id/canada"
            component={CollegeShortlistingCanada}
          />

          <PrivateRoute
            exact
            path="/:student_id/integrated-medicine"
            component={CollegeShortlistingIntegratedMedicine}
          />
          <PrivateRoute
            exact
            path="/:student_id/shortlisted-colleges-uk"
            component={ShortlistedCollegeUK}
          />
          <PrivateRoute
            exact
            path="/:student_id/home"
            component={LoginComponent}
          />
          <PrivateRoute
            exact
            path="/:student_id/extracurriculars"
            component={Extracurriculars}
          />
          <PrivateRoute exact path="/:student_id/faq" component={Faq} />
          <PrivateRoute
            exact
            path="/:student_id/scholarships"
            component={scholarship}
          />
          <PrivateRoute
            exact
            path="/:student_id/summer-programs"
            component={SummerPrograms}
          />
          <PrivateRoute
            exact
            path="/:student_id/summer-plan"
            component={SummerPlan}
          />
          <PrivateRoute
            exact
            path="/:student_id/summer-strategy"
            component={SummerStrategy}
          />
          <PrivateRoute
            exact
            path="/:student_id/independent-projects"
            component={IndependentProjects}
          />

          <PrivateRoute
            exact
            path="/:student_id/independent-project-1"
            component={IndependentProjects}
          />
          <PrivateRoute
            exact
            path="/:student_id/independent-project-2"
            component={IndependentProjects2}
          />
          <PrivateRoute
            exact
            path="/:student_id/independent-project-3"
            component={IndependentProjects3}
          />
          {/* <PrivateRoute
            exact
            path="/:student_id/chat"
            component={DiscussionBoard}
          /> */}
          <PrivateRoute
            exact
            path="/:student_id/email"
            component={DiscussionBoard}
          />
          <PrivateRoute
            exact
            path="/:student_id/reading-list"
            component={ReadingList}
          />
          <PrivateRoute
            exact
            path="/:student_id/summer-school"
            component={SummerSchool}
          />
          <PrivateRoute
            exact
            path="/:student_id/colleges"
            component={Colleges}
          />
          <PrivateRoute
            exact
            path="/:student_id/ngo-list"
            component={NGOList}
          />
          <PrivateRoute
            exact
            path="/:student_id/online-courses"
            component={OnlineCourses}
          />
          <PrivateRoute
            exact
            path="/:student_id/hackathons"
            component={Hackathons}
          />
          <PrivateRoute
            exact
            path="/:student_id/internships"
            component={Internships}
          />
          <PrivateRoute
            exact
            path="/:student_id/olympiads"
            component={Olympiads}
          />
          <PrivateRoute
            exact
            path="/:student_id/competitions"
            component={Competitions}
          />
          <PrivateRoute
            exact
            path="/:student_id/platforms"
            component={Platforms}
          />
          <PrivateRoute
            exact
            path="/:student_id/professors"
            component={Professors}
          />
          <PrivateRoute
            exact
            path="/:student_id/research"
            component={Research}
          />
          <PrivateRoute
            exact
            path="/:student_id/research-focus"
            component={ResearchFocus}
          />
          <PrivateRoute
            exact
            path="/:student_id/research-focus-1"
            component={ResearchFocus}
          />

          <PrivateRoute
            exact
            path="/:student_id/research-focus-2"
            component={ResearchFocus2}
          />
          <PrivateRoute
            exact
            path="/:student_id/research-focus-3"
            component={ResearchFocus3}
          />
          <PrivateRoute
            exact
            path="/:student_id/recommended-books"
            component={RecommendedBooks}
          />
          <PrivateRoute
            exact
            path="/:student_id/resource-downloads"
            component={Downloads}
          />
          <PrivateRoute
            exact
            path="/:student_id/financial-aid"
            component={FinancialAid}
          />
          <PrivateRoute
            exact
            path="/:student_id/shortlisted-colleges"
            component={ShortlistedColleges}
          />
          <PrivateRoute
            exact
            path="/:student_id/final-shortlisted-college"
            component={FinalShortlistedCollege}
          />
          <PrivateRoute
            exact
            path="/:student_id/student-list"
            component={StudentList}
          />
          <PrivateRoute
            exact
            path="/final-shortlisted-college"
            component={FinalShortlistedCollege}
          />

          <PrivateRoute
            exact
            path="/recording/:meetingId"
            component={Recording}
          />
          <PrivateRoute
            exact
            path="/start-meeting/:meeting_id"
            component={ZoomComponent}
          />
          <PrivateRoute exact path="/:student_id/fafsa" component={Fafsa} />

          {/* MANAGER ROUTES */}

          <PrivateRoute exact path="/manager" component={Manager} />

          <PrivateRoute
            exact
            path="/advisor-assessment/collegeShortlist"
            component={CollegeShortlistAssessment}
          />

          <PrivateRoute
            exact
            path="/advisor-assessment/academic"
            component={AcademicAssessment}
          />

          <PrivateRoute
            exact
            path="/advisor-assessment/extracurricular"
            component={ExtracurricularAssessment}
          />

          <PrivateRoute
            exact
            path="/advisor-assessment/summerPlan"
            component={SummerPlanAssessment}
          />

          <PrivateRoute
            exact
            path="/advisor-assessment/researchAssessment"
            component={ResearchAssessment}
          />

          <Route exact path="" component={NotFound} />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default Routes;
