import {
	GET_CURRENT_MEETINGS_REQUEST,
	GET_CURRENT_MEETINGS_SUCCESS,
	GET_CURRENT_MEETINGS_FAILURE,
	GET_PAST_MEETINGS_REQUEST,
	GET_PAST_MEETINGS_SUCCESS,
	GET_PAST_MEETINGS_FAILURE,
	ADD_MEETINGS_REQUEST,
	ADD_MEETINGS_SUCCESS,
	ADD_MEETINGS_FAILURE,
	DELETE_MEETINGS_REQUEST,
	DELETE_MEETINGS_SUCCESS,
	DELETE_MEETINGS_FAILURE,
} from '../constants/meetingConstants'

const initialStateCurrentMeetings = {
	loading: false,
	meetings: [],
	error: null,
	success: null,
}

const initialStatePreviousMeetings = {
	loading: false,
	meetings: [],
	error: null,
	success: null,
}

const initialStateAddExtracurriculars = {
	loading: false,
	error: null,
	success: null,
}

const initialStateDeleteExtracurriculars = {
	loading: false,
	error: null,
	success: null,
}

export const getCurrentMeetingsReducer = (
	state = initialStateCurrentMeetings,
	action
) => {
	switch (action.type) {
		case GET_CURRENT_MEETINGS_REQUEST:
			return {...state, loading: true, meetings: []}
		case GET_CURRENT_MEETINGS_SUCCESS:
			return {
				...state,
				loading: false,
				meetings: action.payload.meetings,
				success: action.payload.success,
				error: null,
			}
		case GET_CURRENT_MEETINGS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				success: null,
			}

		default:
			return state
	}
}

export const getPreviousMeetingsReducer = (
	state = initialStatePreviousMeetings,
	action
) => {
	switch (action.type) {
		case GET_PAST_MEETINGS_REQUEST:
			return {...state, loading: true, meetings: []}
		case GET_PAST_MEETINGS_SUCCESS:
			return {
				...state,
				loading: false,
				meetings: action.payload.meetings,
				success: action.payload.success,
				error: null,
			}
		case GET_PAST_MEETINGS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				success: null,
			}

		default:
			return state
	}
}

export const addMeetingsReducer = (
	state = initialStateAddExtracurriculars,
	action
) => {
	switch (action.type) {
		case ADD_MEETINGS_REQUEST:
			return {...state, loading: true}
		case ADD_MEETINGS_SUCCESS:
			return {
				...state,
				loading: false,
				success: action.payload.success,
				error: null,
			}
		case ADD_MEETINGS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				success: null,
			}

		default:
			return state
	}
}

export const deleteMeetingReducer = (
	state = initialStateDeleteExtracurriculars,
	action
) => {
	switch (action.type) {
		case DELETE_MEETINGS_REQUEST:
			return {...state, loading: true}
		case DELETE_MEETINGS_SUCCESS:
			return {
				...state,
				loading: false,
				success: action.payload.success,
				error: null,
			}
		case DELETE_MEETINGS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				success: null,
			}

		default:
			return state
	}
}
