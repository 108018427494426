export const ADD_COLLEGE = "ADD_COLLEGE";
export const REMOVE_COLLEGE = "REMOVE_COLLEGE";
export const LOAD_SHORTLIST = "LOAD_SHORTLIST";
export const REMOVE_SHORTLIST = "REMOVE_SHORTLIST";
export const REMOVE_ALL_COLLEGES = "REMOVE_ALL_COLLEGES";

export const ADD_COLLEGE_UK = "ADD_COLLEGE_UK";
export const REMOVE_COLLEGE_UK = "REMOVE_COLLEGE_UK";
export const LOAD_SHORTLIST_UK = "LOAD_SHORTLIST_UK";
export const REMOVE_SHORTLIST_UK = "REMOVE_SHORTLIST_UK";
export const REMOVE_ALL_COLLEGES_UK = "REMOVE_ALL_COLLEGES_UK";

export const ADD_COLLEGE_CA = "ADD_COLLEGE_CA";
export const REMOVE_COLLEGE_CA = "REMOVE_COLLEGE_CA";
export const LOAD_SHORTLIST_CA = "LOAD_SHORTLIST_CA";
export const REMOVE_SHORTLIST_CA = "REMOVE_SHORTLIST_CA";
export const REMOVE_ALL_COLLEGES_CA = "REMOVE_ALL_COLLEGES_CA";

export const ADD_COLLEGE_APAC = "ADD_COLLEGE_APAC";
export const REMOVE_COLLEGE_APAC = "REMOVE_COLLEGE_APAC";
export const LOAD_SHORTLIST_APAC = "LOAD_SHORTLIST_APAC";
export const REMOVE_SHORTLIST_APAC = "REMOVE_SHORTLIST_APAC";
export const REMOVE_ALL_COLLEGES_APAC = "REMOVE_ALL_COLLEGES_APAC";

export const ADD_COLLEGE_EU = "ADD_COLLEGE_EU";
export const REMOVE_COLLEGE_EU = "REMOVE_COLLEGE_EU";
export const LOAD_SHORTLIST_EU = "LOAD_SHORTLIST_EU";
export const REMOVE_SHORTLIST_EU = "REMOVE_SHORTLIST_EU";
export const REMOVE_ALL_COLLEGES_EU = "REMOVE_ALL_COLLEGES_EU";

export const ADD_COLLEGE_INTEGRATED_MED = "ADD_COLLEGE_INTEGRATED_MED";
export const REMOVE_COLLEGE_INTEGRATED_MED = "REMOVE_COLLEGE_INTEGRATED_MED";
export const LOAD_SHORTLIST_INTEGRATED_MEDA = "LOAD_SHORTLIST_INTEGRATED_MED";
export const REMOVE_SHORTLIST_INTEGRATED_MED =
  "REMOVE_SHORTLIST_INTEGRATED_MED";
export const REMOVE_ALL_COLLEGES_INTEGRATED_MED =
  "REMOVE_ALL_COLLEGES_INTEGRATED_MED";
