export const STUDENT_LOGIN_REQUEST = 'STUDENT_LOGIN_REQUEST'
export const STUDENT_LOGIN_SUCCESS = 'STUDENT_LOGIN_SUCCESS'
export const STUDENT_LOGIN_FAILURE = 'STUDENT_LOGIN_FAILURE'

export const ADVISOR_LOGIN_REQUEST = 'ADVISOR_LOGIN_REQUEST'
export const ADVISOR_LOGIN_SUCCESS = 'ADVISOR_LOGIN_SUCCESS'
export const ADVISOR_LOGIN_FAILURE = 'ADVISOR_LOGIN_FAILURE'

export const MANAGER_LOGIN_REQUEST = 'MANAGER_LOGIN_REQUEST'
export const MANAGER_LOGIN_SUCCESS = 'MANAGER_LOGIN_SUCCESS'
export const MANAGER_LOGIN_FAILURE = 'MANAGER_LOGIN_FAILURE'

export const LOGOUT = 'LOGOUT'

export const RELOAD_USER_DETAILS_REQUEST = 'RELOAD_USER_DETAILS_REQUEST'
export const RELOAD_USER_DETAILS_SUCCESS = 'RELOAD_USER_DETAILS_SUCCESS'
export const RELOAD_USER_DETAILS_FAILURE = 'RELOAD_USER_DETAILS_FAILURE'

export const LOAD_USER_DETAILS_REQUEST = 'LOAD_USER_DETAILS_REQUEST'
export const LOAD_USER_DETAILS_SUCCESS = 'LOAD_USER_DETAILS_SUCCESS'
export const LOAD_USER_DETAILS_FAILURE = 'LOAD_USER_DETAILS_FAILURE'
