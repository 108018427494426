import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Routes from "./Routes/Routes";
import { useDispatch, useSelector } from "react-redux";
import { reloadAuthDetails } from "./actions/authActions";
import AlertDialogBox from "./components/AlertDialogBox";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.isAuthenticated !== true && auth.token) {
      dispatch(reloadAuthDetails(auth.token));
    }
    if (
      auth.userRole &&
      auth.userRole === 2 &&
      (auth.userID === 67 ||
        auth.userID === 15048 ||
        auth.userID === 17186 ||
        auth.userID === 39670 ||
        auth.userID === 37650 ||
        auth.userID === 34600 ||
        auth.userID === 13113 ||
        auth.userID === 82)
    ) {
      const script = document.createElement("script");
      script.text = `window.embeddedChatbotConfig = {chatbotId: "QO6ntEXzlbj5xjyh8zQ55",domain: "www.chatbase.co"}`;

      const script2 = document.createElement("script");
      script2.src = "https://www.chatbase.co/embed.min.js";
      script2.chatbotId = "QO6ntEXzlbj5xjyh8zQ55";
      script2.domain = "www.chatbase.co";
      script2.defer = true;
      // Append the script to the designated container
      const container = document.getElementById("chatbaseScriptContainer");
      container.appendChild(script);
      container.appendChild(script2);

      // Cleanup: remove the script when the component is unmounted
      return () => {
        container.removeChild(script);
      };
    }
  }, [dispatch, auth.isAuthenticated, auth.token, auth.userRole]);
  //Trying to redeploy
  return (
    <div className="main-area-wrap">
      <AlertDialogBox />
      <Router>
        <Route component={Routes} />
      </Router>
    </div>
  );
}

export default App;
