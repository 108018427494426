export const GET_CURRENT_MEETINGS_REQUEST = 'GET_CURRENT_MEETINGS_REQUEST'
export const GET_CURRENT_MEETINGS_SUCCESS = 'GET_CURRENT_MEETINGS_SUCCESS'
export const GET_CURRENT_MEETINGS_FAILURE = 'GET_CURRENT_MEETINGS_FAILURE'

export const GET_PAST_MEETINGS_REQUEST = 'GET_PAST_MEETINGS_REQUEST'
export const GET_PAST_MEETINGS_SUCCESS = 'GET_PAST_MEETINGS_SUCCESS'
export const GET_PAST_MEETINGS_FAILURE = 'GET_PAST_MEETINGS_FAILURE'

export const ADD_MEETINGS_REQUEST = 'ADD_MEETINGS_REQUEST'
export const ADD_MEETINGS_SUCCESS = 'ADD_MEETINGS_SUCCESS'
export const ADD_MEETINGS_FAILURE = 'ADD_MEETINGS_FAILURE'

export const DELETE_MEETINGS_REQUEST = 'DELETE_MEETINGS_REQUEST'
export const DELETE_MEETINGS_SUCCESS = 'DELETE_MEETINGS_SUCCESS'
export const DELETE_MEETINGS_FAILURE = 'DELETE_MEETINGS_FAILURE'
