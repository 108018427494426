import {
  ADD_COLLEGE,
  ADD_COLLEGE_APAC,
  ADD_COLLEGE_CA,
  ADD_COLLEGE_EU,
  ADD_COLLEGE_UK,
  ADD_COLLEGE_INTEGRATED_MED,
  REMOVE_COLLEGE,
  REMOVE_COLLEGE_APAC,
  REMOVE_COLLEGE_CA,
  REMOVE_COLLEGE_EU,
  REMOVE_COLLEGE_UK,
  REMOVE_COLLEGE_INTEGRATED_MED,
  REMOVE_ALL_COLLEGES,
  REMOVE_ALL_COLLEGES_UK,
  REMOVE_ALL_COLLEGES_CA,
  REMOVE_ALL_COLLEGES_APAC,
  REMOVE_ALL_COLLEGES_EU,
  REMOVE_ALL_COLLEGES_INTEGRATED_MED,
} from "../constants/shortlistingConstants";
import {
  shortlistedColleges,
  shortlistedCollegesAPAC,
  shortlistedCollegesCA,
  shortlistedCollegesUK,
  shortlistedCollegesEU,
  shortlistedCollegesIntegratedMed,
} from "../config/localStorage";

import { reactLocalStorage } from "reactjs-localstorage";

const initialState = {
  shortlistedColleges: reactLocalStorage.getObject(
    shortlistedColleges,
    [],
    true
  ),
};

const initialStateUK = {
  shortlistedCollegesUK: reactLocalStorage.getObject(
    shortlistedCollegesUK,
    [],
    true
  ),
};

const initialStateCA = {
  shortlistedCollegesCA: reactLocalStorage.getObject(
    shortlistedCollegesCA,
    [],
    true
  ),
};

const initialStateAPAC = {
  shortlistedCollegesAPAC: reactLocalStorage.getObject(
    shortlistedCollegesAPAC,
    [],
    true
  ),
};

const initialStateEU = {
  shortlistedCollegesEU: reactLocalStorage.getObject(
    shortlistedCollegesEU,
    [],
    true
  ),
};

const initialStateIntegratedMed = {
  shortlistedCollegesIntegratedMed: reactLocalStorage.getObject(
    shortlistedCollegesIntegratedMed,
    [],
    true
  ),
};

export const shortlistReducer = (state = initialState, action) => {
  const { type, payload } = action;

  let existed_item = null;

  switch (type) {
    case ADD_COLLEGE:
      existed_item = state.shortlistedColleges.find(
        (college) => college.College_Name === payload.College_Name
      );

      if (existed_item) {
        // if the college is already present in the cart

        reactLocalStorage.setObject(
          shortlistedColleges,
          state.shortlistedColleges
        );

        return {
          ...state,
          shortlistedColleges: state.shortlistedColleges,
        };
      } else {
        // If we are adding a new college to the cart

        reactLocalStorage.setObject(shortlistedColleges, [
          ...state.shortlistedColleges,
          payload,
        ]);

        return {
          ...state,
          shortlistedColleges: [...state.shortlistedColleges, payload],
        };
      }

    // case LOAD_SHORTLIST:
    // 	return {
    // 		...state,
    // 		items: payload.items,
    // 		total: payload.total,
    // 	}

    case REMOVE_COLLEGE:
      // let itemToRemove = state.shortlistedColleges.find(
      // 	(college) => college.College_Name === payload.College_Name
      // )
      let new_items = state.shortlistedColleges.filter(
        (college) => college.College_Name !== payload.College_Name
      );

      reactLocalStorage.setObject(shortlistedColleges, new_items);

      return {
        ...state,
        shortlistedColleges: new_items,
      };

    case REMOVE_ALL_COLLEGES:
      reactLocalStorage.setObject(shortlistedColleges, []);
      return {
        ...state,
        shortlistedColleges: [],
      };

    // case REMOVE_SHORTLIST:
    // 	reactLocalStorage.remove(localStorageCartTotal)
    // 	reactLocalStorage.remove(localStorageCart)
    // 	return {
    // 		...state,
    // 		items: [],
    // 		total: 0,
    // 	}
    default:
      return state;
  }
};

export const shortlistUKReducer = (state = initialStateUK, action) => {
  const { type, payload } = action;

  let existed_item = null;

  switch (type) {
    case ADD_COLLEGE_UK:
      existed_item = state.shortlistedCollegesUK.find(
        (college) =>
          college.Colleges.UniversityName === payload.Colleges.UniversityName
      );

      if (existed_item) {
        // if the college is already present in the cart

        reactLocalStorage.setObject(
          shortlistedCollegesUK,
          state.shortlistedCollegesUK
        );

        return {
          ...state,
          shortlistedCollegesUK: state.shortlistedCollegesUK,
        };
      } else {
        // If we are adding a new college to the cart

        reactLocalStorage.setObject(shortlistedCollegesUK, [
          ...state.shortlistedCollegesUK,
          payload,
        ]);

        return {
          ...state,
          shortlistedCollegesUK: [...state.shortlistedCollegesUK, payload],
        };
      }

    // case LOAD_SHORTLIST:
    // 	return {
    // 		...state,
    // 		items: payload.items,
    // 		total: payload.total,
    // 	}

    case REMOVE_COLLEGE_UK:
      // let itemToRemove = state.shortlistedColleges.find(
      // 	(college) => college.College_Name === payload.College_Name
      // )
      let new_items = state.shortlistedCollegesUK.filter(
        (college) =>
          college.Colleges.UniversityName !== payload.Colleges.UniversityName
      );

      reactLocalStorage.setObject(shortlistedCollegesUK, new_items);

      return {
        ...state,
        shortlistedCollegesUK: new_items,
      };

    case REMOVE_ALL_COLLEGES_UK:
      reactLocalStorage.setObject(shortlistedCollegesUK, []);
      return {
        ...state,
        shortlistedCollegesUK: [],
      };

    // case REMOVE_SHORTLIST:
    // 	reactLocalStorage.remove(localStorageCartTotal)
    // 	reactLocalStorage.remove(localStorageCart)
    // 	return {
    // 		...state,
    // 		items: [],
    // 		total: 0,
    // 	}
    default:
      return state;
  }
};

export const shortlistCAReducer = (state = initialStateCA, action) => {
  const { type, payload } = action;

  let existed_item = null;

  switch (type) {
    case ADD_COLLEGE_CA:
      existed_item = state.shortlistedCollegesCA.find(
        (college) =>
          college.Colleges.UniversityName === payload.Colleges.UniversityName
      );

      if (existed_item) {
        // if the college is already present in the cart

        reactLocalStorage.setObject(
          shortlistedCollegesCA,
          state.shortlistedCollegesCA
        );

        return {
          ...state,
          shortlistedCollegesCA: state.shortlistedCollegesCA,
        };
      } else {
        // If we are adding a new college to the cart

        reactLocalStorage.setObject(shortlistedCollegesCA, [
          ...state.shortlistedCollegesCA,
          payload,
        ]);

        return {
          ...state,
          shortlistedCollegesCA: [...state.shortlistedCollegesCA, payload],
        };
      }

    case REMOVE_COLLEGE_CA:
      let new_items = state.shortlistedCollegesCA.filter(
        (college) =>
          college.Colleges.UniversityName !== payload.Colleges.UniversityName
      );

      reactLocalStorage.setObject(shortlistedCollegesCA, new_items);

      return {
        ...state,
        shortlistedCollegesCA: new_items,
      };

    case REMOVE_ALL_COLLEGES_CA:
      reactLocalStorage.setObject(shortlistedCollegesCA, []);
      return {
        ...state,
        shortlistedCollegesCA: [],
      };

    default:
      return state;
  }
};

export const shortlistAPACReducer = (state = initialStateAPAC, action) => {
  const { type, payload } = action;

  let existed_item = null;

  switch (type) {
    case ADD_COLLEGE_APAC:
      existed_item = state.shortlistedCollegesAPAC.find(
        (college) =>
          college.Colleges.UniversityName === payload.Colleges.UniversityName
      );

      if (existed_item) {
        // if the college is already present in the cart

        reactLocalStorage.setObject(
          shortlistedCollegesAPAC,
          state.shortlistedCollegesAPAC
        );

        return {
          ...state,
          shortlistedCollegesAPAC: state.shortlistedCollegesAPAC,
        };
      } else {
        // If we are adding a new college to the cart

        reactLocalStorage.setObject(shortlistedCollegesAPAC, [
          ...state.shortlistedCollegesAPAC,
          payload,
        ]);

        return {
          ...state,
          shortlistedCollegesAPAC: [...state.shortlistedCollegesAPAC, payload],
        };
      }

    case REMOVE_COLLEGE_APAC:
      let new_items = state.shortlistedCollegesAPAC.filter(
        (college) =>
          college.Colleges.UniversityName !== payload.Colleges.UniversityName
      );

      reactLocalStorage.setObject(shortlistedCollegesAPAC, new_items);

      return {
        ...state,
        shortlistedCollegesAPAC: new_items,
      };

    case REMOVE_ALL_COLLEGES_APAC:
      reactLocalStorage.setObject(shortlistedCollegesAPAC, []);
      return {
        ...state,
        shortlistedCollegesAPAC: [],
      };

    default:
      return state;
  }
};

export const shortlistEUReducer = (state = initialStateEU, action) => {
  const { type, payload } = action;

  let existed_item = null;

  switch (type) {
    case ADD_COLLEGE_EU:
      existed_item = state.shortlistedCollegesEU.find(
        (college) =>
          college.Colleges.UniversityName === payload.Colleges.UniversityName
      );

      if (existed_item) {
        // if the college is already present in the cart

        reactLocalStorage.setObject(
          shortlistedCollegesEU,
          state.shortlistedCollegesEU
        );

        return {
          ...state,
          shortlistedCollegesEU: state.shortlistedCollegesEU,
        };
      } else {
        // If we are adding a new college to the cart

        reactLocalStorage.setObject(shortlistedCollegesEU, [
          ...state.shortlistedCollegesEU,
          payload,
        ]);

        return {
          ...state,
          shortlistedCollegesEU: [...state.shortlistedCollegesEU, payload],
        };
      }

    case REMOVE_COLLEGE_EU:
      let new_items = state.shortlistedCollegesEU.filter(
        (college) =>
          college.Colleges.UniversityName !== payload.Colleges.UniversityName
      );

      reactLocalStorage.setObject(shortlistedCollegesEU, new_items);

      return {
        ...state,
        shortlistedCollegesEU: new_items,
      };

    case REMOVE_ALL_COLLEGES_EU:
      reactLocalStorage.setObject(shortlistedCollegesEU, []);
      return {
        ...state,
        shortlistedCollegesEU: [],
      };

    default:
      return state;
  }
};

export const shortlistIntegratedMedReducer = (
  state = initialStateIntegratedMed,
  action
) => {
  const { type, payload } = action;

  let existed_item = null;

  switch (type) {
    case ADD_COLLEGE_INTEGRATED_MED:
      existed_item = state.shortlistedCollegesIntegratedMed.find(
        (college) => college.programname === payload.programname
      );

      if (existed_item) {
        // if the college is already present in the cart

        reactLocalStorage.setObject(
          shortlistedCollegesIntegratedMed,
          state.shortlistedCollegesIntegratedMed
        );

        return {
          ...state,
          shortlistedCollegesIntegratedMed:
            state.shortlistedCollegesIntegratedMed,
        };
      } else {
        // If we are adding a new college to the cart

        reactLocalStorage.setObject(shortlistedCollegesIntegratedMed, [
          ...state.shortlistedCollegesIntegratedMed,
          payload,
        ]);

        return {
          ...state,
          shortlistedCollegesIntegratedMed: [
            ...state.shortlistedCollegesIntegratedMed,
            payload,
          ],
        };
      }

    case REMOVE_COLLEGE_INTEGRATED_MED:
      let new_items = state.shortlistedCollegesIntegratedMed.filter(
        (college) => college.programname !== payload.programname
      );

      reactLocalStorage.setObject(shortlistedCollegesIntegratedMed, new_items);

      return {
        ...state,
        shortlistedCollegesIntegratedMed: new_items,
      };

    case REMOVE_ALL_COLLEGES_INTEGRATED_MED:
      reactLocalStorage.setObject(shortlistedCollegesIntegratedMed, []);
      return {
        ...state,
        shortlistedCollegesIntegratedMed: [],
      };

    default:
      return state;
  }
};
