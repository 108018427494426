import "./Loading.scss";

import Holo from "../../assets/Images/loader_animated.svg"
import React from "react";

function Loading() {
  return (
    <div className="full">
      <div className="LoaderClass">
        <img
          src={Holo}
          alt="loader"
          style={{
            height: "70px",
          }}
        />
      </div>
    </div>
  );
}

export default Loading;
