import {
	GET_EXTRACURRICULAR_REQUEST,
	GET_EXTRACURRICULAR_SUCCESS,
	GET_EXTRACURRICULAR_FAILURE,
	ADD_EXTRACURRICULAR_REQUEST,
	ADD_EXTRACURRICULAR_SUCCESS,
	ADD_EXTRACURRICULAR_FAILURE,
	UPDATE_EXTRACURRICULAR_REQUEST,
	UPDATE_EXTRACURRICULAR_SUCCESS,
	UPDATE_EXTRACURRICULAR_FAILURE,
} from '../constants/extracurricularConstants'

const initialStateGetExtracurriculars = {
	loading: false,
	extracurriculars: [],
	error: null,
	success: null,
}

const initialStateAddExtracurriculars = {
	loading: false,
	error: null,
	success: null,
}

const initialStateUpdateExtracurriculars = {
	loading: false,
	extracurriculars: [],
	error: null,
	success: null,
}

export const getExtracurricularReducer = (
	state = initialStateGetExtracurriculars,
	action
) => {
	switch (action.type) {
		case GET_EXTRACURRICULAR_REQUEST:
			return {...state, loading: true, extracurriculars: []}
		case GET_EXTRACURRICULAR_SUCCESS:
			return {
				...state,
				loading: false,
				extracurriculars: action.payload.extracurriculars,
				success: action.payload.success,
				error: null,
			}
		case GET_EXTRACURRICULAR_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				success: null,
			}

		default:
			return state
	}
}

export const addExtracurricularReducer = (
	state = initialStateAddExtracurriculars,
	action
) => {
	switch (action.type) {
		case ADD_EXTRACURRICULAR_REQUEST:
			return {...state, loading: true, extracurriculars: []}
		case ADD_EXTRACURRICULAR_SUCCESS:
			return {
				...state,
				loading: false,
				success: action.payload.success,
				error: null,
			}
		case ADD_EXTRACURRICULAR_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				success: null,
			}

		default:
			return state
	}
}

export const updateExtracurricularReducer = (
	state = initialStateUpdateExtracurriculars,
	action
) => {
	switch (action.type) {
		case UPDATE_EXTRACURRICULAR_REQUEST:
			return {...state, loading: true}
		case UPDATE_EXTRACURRICULAR_SUCCESS:
			return {
				...state,
				loading: false,
				success: action.payload.success,
				error: null,
			}
		case UPDATE_EXTRACURRICULAR_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				success: null,
			}

		default:
			return state
	}
}
